import { createApp } from 'vue'
import App from './h5.vue'
import 'normalize.css'
import router from './router'
import 'amfe-flexible/index.js'
import '@/styles/index.less'
import 'windi.css'

import 'vant/lib/index.css'
import { Swipe, SwipeItem, Overlay, List, Col, Row, Loading, Form, Field, CellGroup, Button, Popup, Picker, Area, Uploader, Dialog, Radio, RadioGroup, Notify } from 'vant'

import { sendNativeMessage } from '@/utils/message.js'

const app = createApp(App)

// app.config.globalProperties.$sessionId = ''

import { getQueryVariable } from '@/utils/tools.js'
const result = getQueryVariable('s');
if (result) {
  app.config.globalProperties.$sessionId = result
};

sendNativeMessage('LLWKFormAppAction', 'getSessionId', function (result) {
  app.config.globalProperties.$sessionId = result
})

const vantComponents = [Swipe, SwipeItem, Overlay, List, Col, Row, Loading, Form, Field, CellGroup, Button, Popup, Picker, Area, Uploader, Dialog, Radio, RadioGroup, Notify]
for (let i = 0; i < vantComponents.length; i++) {
  app.use(vantComponents[i])
}

app.use(router).mount('#app')

if (process.env.NODE_ENV !== 'production' && location.href.indexOf('localhost') < 0) {
  new VConsole()
}



export default app
